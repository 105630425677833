import {
	AfterContentInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	inject,
	OnInit,
	Renderer2
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { MainMenuComponent } from './components/menu/main-menu/main-menu.component';
import { TestCardComponent } from './components/test/test-card/test-card.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LoadingService } from './services/loading.service';
import { ParamsService } from './services/params.service';
import { MobileService } from './services/mobile.service';
import { MenuService } from './services/menu.service';
import { MenuActionType } from './models/app/menu-action.model';
import { fadeInLeftOnEnterAnimation, fadeOutLeftOnLeaveAnimation } from 'angular-animations';
import { UnirCrosscuttingService } from '@unir/core/auth/crosscutting';
import { UnirCoreService } from '@unir/core';
import { User } from '@unir/core/auth/crosscutting/models/user.model';
@Component({
	standalone: true,
	imports: [CommonModule, RouterModule, LoadingComponent, MainMenuComponent, TestCardComponent, LoadingComponent],
	selector: 'unir-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [fadeInLeftOnEnterAnimation({ anchor: 'enter' }), fadeOutLeftOnLeaveAnimation({ anchor: 'leave' })]
})
export class AppComponent implements AfterContentInit, OnInit {
	constructor(private coreService: UnirCoreService, private crosscuttingService: UnirCrosscuttingService) {}
	@HostListener('window:resize')
	onResize() {
		this.mobile = window.innerWidth <= 1024;
		this.mobileService.display(this.mobile);
		this.cdr.detectChanges();
	}

	cdr = inject(ChangeDetectorRef);
	mobile!: boolean;
	loadingRequests = 0;
	loadingService = inject(LoadingService);
	paramsService = inject(ParamsService);
	loaded$: Observable<boolean> = this.coreService.configurationLoaded$ as Observable<boolean>;
	user$: Observable<User> = this.crosscuttingService.user$ as Observable<User>;
	logged = false;
	openMenu = true;
	mobileService = inject(MobileService);
	menuRequiredClosed = false;

	private route = inject(ActivatedRoute);
	private router = inject(Router);
	private menuService = inject(MenuService);
	private renderer = inject(Renderer2);
	private el = inject(ElementRef);
	ngOnInit() {
		this.loadingService.display(true);
		this.redirect();
		this.onResize();

		this.menuService.action$.pipe().subscribe((menu) => {
			if (MenuActionType.OPEN_MENU === menu.action) {
				this.openMenu = true;
			}
			if (MenuActionType.CLOSE_MENU === menu.action) {
				this.openMenu = false;
			}
			this.cdr.detectChanges();
		});

		this.manageTabbing();
		this.initHideMenu();
	}

	ngAfterContentInit() {
		this.loadingService.status.subscribe((status) => {
			if (status) {
				this.loadingRequests++;
			} else {
				this.loadingRequests--;
			}

			this.loadingService.loading = this.loadingRequests > 0;
		});

		this.listenQueryParams();
	}

	initHideMenu() {
		this.menuService.menuClosed$.subscribe((val) => {
			this.menuRequiredClosed = val;
			this.cdr.detectChanges();
		});
	}

	redirect() {
		this.user$.subscribe((user) => {
			const url = sessionStorage.getItem('returnUrl');
			if (!user) {
				const currentUrl = new URL(window.location.href);
				const newurl = currentUrl.pathname + currentUrl.search;

				if (!window.location.href.includes('#access_token')) {
					sessionStorage.setItem('returnUrl', newurl.split('#')[0]);
				}
			} else if (url) {
				this.router.navigateByUrl(url);
				sessionStorage.removeItem('returnUrl');
			}

			if (user) {
				this.logged = true;
			}
		});
	}

	listenQueryParams() {
		this.route.queryParamMap.pipe().subscribe((params) => {
			const paramSubjectId = params.keys.find((key) => key.toLowerCase() === 'subjectid');
			const paramTopicId = params.keys.find((key) => key.toLowerCase() === 'topicid');

			if (paramSubjectId) {
				const subjectId = params.get(paramSubjectId);
				this.paramsService.displaySubjectId(subjectId !== null ? parseInt(subjectId) : null);
			} else {
				this.paramsService.displaySubjectId(null);
			}

			if (paramTopicId) {
				const topicId = params.get(paramTopicId);
				this.paramsService.displayTopicId(
					topicId !== null
						? topicId.includes(',')
							? topicId.split(',').map((x) => parseInt(x))
							: [parseInt(topicId)]
						: null
				);
			} else {
				this.paramsService.displayTopicId(null);
			}
		});
	}

	private manageTabbing() {
		this.renderer.listen('document', 'keydown', (event) => {
			if (event.key === 'Tab') {
				this.renderer.addClass(this.el.nativeElement.ownerDocument.body, 'user-is-tabbing');
			}
		});

		this.renderer.listen('document', 'mousedown', () => {
			this.renderer.removeClass(this.el.nativeElement.ownerDocument.body, 'user-is-tabbing');
		});
	}

	get menuVisible() {
		return !this.menuRequiredClosed && (this.openMenu || !this.mobile);
	}
}
