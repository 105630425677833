import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatResponseList } from '../../models/api/chat-response-list.model';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { FormatDatePipe } from '../../pipes/format-date.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'unir-ai-chat-card',
	standalone: true,
	imports: [CommonModule, ButtonModule, FormsModule, FormatDatePipe, TranslateModule],
	templateUrl: './chat-card.component.html',
	styleUrls: ['./chat-card.component.scss']
})
export class ChatCardComponent implements OnInit {
	@Input() chat!: ChatResponseList;

	@Input() currentChatId?: number;

	@Output() chatSelected = new EventEmitter<number>();

	@Output() deleteChat = new EventEmitter<number>();

	@Output() newName = new EventEmitter<{ id: number; name: string }>();

	@ViewChild('input') input!: ElementRef<HTMLInputElement>;

	title? = '';

	ngOnInit() {
		this.title = this.chat.chatName;
	}

	showDialog = false;

	edit = false;

	removeChat() {
		this.deleteChat.emit(this.chat.chatId);
	}
	cdr = inject(ChangeDetectorRef);
	renameChat() {
		this.edit = true;
		setTimeout(() => {
			this.input.nativeElement.focus();
		}, 0);
	}

	onNameChange() {
		this.edit = false;
		if (this.title && this.title !== this.chat.chatName) {
			this.newName.emit({ id: this.chat.chatId, name: this.title });
		}
	}

	openChat() {
		if (this.edit) {
			return;
		}

		this.showDialog = false;
		this.chatSelected.emit(this.chat.chatId);
	}
}
