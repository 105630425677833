import { ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { TopicsBySubjectResponse } from '../../../models/api/topics-by-subject.model';
import { FormsModule } from '@angular/forms';
import { collapseAnimation } from 'angular-animations';

@Component({
	selector: 'unir-ai-menu-button',
	standalone: true,
	imports: [CommonModule, ButtonModule, CheckboxModule, FormsModule],
	templateUrl: './menu-button.component.html',
	styleUrls: ['./menu-button.component.scss'],
	animations: [collapseAnimation()]
})
export class MenuButtonComponent {
	@Input() topicsBySubject!: TopicsBySubjectResponse;
	@Input() topicsSelected: number[] = [];
	@Output() topicsSelectedChange: EventEmitter<number[]> = new EventEmitter<number[]>();
	@Output() topicSelected: EventEmitter<number> = new EventEmitter<number>();
	cdr = inject(ChangeDetectorRef);
	protected collapse = true;

	toggleCollapse() {
		this.cdr.detectChanges();
		this.collapse = !this.collapse;
		this.cdr.detectChanges();
	}

	onModelChange() {
		this.topicsSelectedChange.emit(this.topicsSelected);
	}
}
