import { Injectable, inject } from '@angular/core';
import { APIURL } from '../../../app';
import { HttpClient } from '@angular/common/http';
import { FrequentlyQuestions } from '../models/api/frequently-questions.response.model';
import { ChatTopicResponse } from '../models/api/chat-topics-response.model';
import { TopicsBySubjectResponse } from '../models/api/topics-by-subject.model';
import { TopicsResponse } from '../models/api/topic-response.model';
import { Observable, of, take, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TopicService {
	apiUrl = APIURL;

	httpClient = inject(HttpClient);

	getTopicsBySubject(subjectId: number) {
		const cacheKey = 'topicsBySubject' + subjectId;
		const observable = this.httpClient
			.get<TopicsBySubjectResponse>(this.apiUrl + `/topics?SubjectId=${subjectId}`)
			.pipe(
				tap((res) => {
					sessionStorage.setItem(cacheKey, JSON.stringify(res));
					res.topics.sort((a, b) => {
						return parseInt(a.name.split(' ')[0]) - parseInt(b.name.split(' ')[0]);
					});
				})
			);

		return this.returnCacheObservable(cacheKey, observable);
	}

	getTopicsById(topicID: number) {
		const cacheKey = 'topicsById' + topicID;
		const observable = this.httpClient
			.get<TopicsResponse>(this.apiUrl + `/topics/${topicID}/info`)
			.pipe(tap((res) => sessionStorage.setItem(cacheKey, JSON.stringify(res))));

		return this.returnCacheObservable(cacheKey, observable);
	}

	getTopicsByChat(chatId: number) {
		const cacheKey = 'topicsByChat' + chatId;
		const observable = this.httpClient
			.get<ChatTopicResponse>(this.apiUrl + `/chat/${chatId}/topics`)
			.pipe(tap((res) => sessionStorage.setItem(cacheKey, JSON.stringify(res))));

		return this.returnCacheObservable(cacheKey, observable);
	}

	getTopicsInfo(topicIDs: number[]) {
		let query = '/';
		if (topicIDs?.length) {
			let firstExecution = true;
			topicIDs.forEach((id) => {
				firstExecution ? (query = query.concat(`?TopicIds=${id}`)) : (query = query.concat(`&TopicIds=${id}`));
				firstExecution = false;
			});
		} else {
			query = '';
		}

		const cacheKey = 'topicsInfo' + topicIDs.sort().join('-');
		const observable = this.httpClient.get<TopicsInfoResponse>(`${this.apiUrl}/topics/info/${query}`).pipe(
			tap((res) => {
				sessionStorage.setItem(cacheKey, JSON.stringify(res));
				res.topics.sort((a, b) => {
					return parseInt(a.name.split(' ')[0]) - parseInt(b.name.split(' ')[0]);
				});
			})
		);

		return this.returnCacheObservable(cacheKey, observable);
	}

	getFrequentQuestions(topicIDs: number[]) {
		let query = '/';
		if (topicIDs?.length) {
			let firstExecution = true;
			topicIDs.forEach((id) => {
				firstExecution ? (query = query.concat(`?topicId=${id}`)) : (query = query.concat(`&topicId=${id}`));
				firstExecution = false;
			});
		} else {
			query = '';
		}

		const cacheKey = 'frequentQuestions' + topicIDs.sort().join('-');
		const observable = this.httpClient
			.get<FrequentlyQuestions>(this.apiUrl + `/topics/frequent-questions${query}`)
			.pipe(tap((res) => sessionStorage.setItem(cacheKey, JSON.stringify(res))));

		return this.returnCacheObservable(cacheKey, observable);
	}

	getFrequentQuestionsByTopic(topicId: number) {
		const cacheKey = 'frequentQuestionsByTopic' + topicId;
		const observable = this.httpClient
			.get<FrequentlyQuestions>(this.apiUrl + `/topics/${topicId}/frequent-questions`)
			.pipe(tap((res) => sessionStorage.setItem(cacheKey, JSON.stringify(res))));

		return this.returnCacheObservable(cacheKey, observable);
	}

	private returnCacheObservable<T>(cacheKey: string, fallbackObservable: Observable<T>): Observable<T> {
		const cachedResponse = sessionStorage.getItem(cacheKey);

		if (cachedResponse) {
			try {
				return of(JSON.parse(cachedResponse)).pipe(take(1));
			} catch (error) {
				sessionStorage.removeItem(cacheKey);
			}
		}
		return fallbackObservable.pipe(tap((res) => sessionStorage.setItem(cacheKey, JSON.stringify(res))));
	}
}
export interface TopicsInfoResponse {
	topics: TopicsResponse[];
}
