export interface MenuAction {
	action: MenuActionType;
	value?: string | ChatInfo;
}

export enum MenuActionType {
	THEME = 'THEME',
	BACK = 'BACK',
	CHAT = 'CHAT',
	RESET_CHAT = 'RESET_CHAT',
	REFRESH_CHAT_LIST = 'REFRESH_CHAT_LIST',
	OPEN_CHAT = 'OPEN_CHAT',
	CREATE_TEST = 'CREATE_TEST',
	CREATE_SUMMARY = 'CREATE_SUMMARY',
	CREATE_TERM = 'CREATE_TERM',
	CREATE_FORMULA = 'CREATE_FORMULA',
	CREATE_CASE_STUDY = 'CREATE_PRACTICAL_TEST',
	SHOW_THEME = 'SHOW_THEME',
	CLOSE_THEME = 'CLOSE_THEME',
	OPEN_MENU = 'OPEN_MENU',
	CLOSE_MENU = 'CLOSE_MENU'
}

export interface ChatInfo {
	chatId: number;
	topicId: number;
}
