import {
	AfterViewChecked,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	ElementRef,
	HostListener,
	OnInit,
	ViewChild,
	inject
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { MenuService } from '../../../services/menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuActionType } from '../../../models/app/menu-action.model';
import { appRoutesType } from '../../../app.routes';
import { ChatService } from '../../../services/chat.service';
import { ChatList } from '../../../models/api/chat-response-list.model';
import { ChatCardComponent } from '../../chat-card/chat-card.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TopicService } from '../../../services/topic.service';
import { TopicsBySubjectResponse } from '../../../models/api/topics-by-subject.model';
import { TopicsResponse } from '../../../models/api/topic-response.model';
import { LoadingService } from '../../../services/loading.service';
import { catchError, of } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { ParamsService } from '../../../services/params.service';
import { MenuButtonComponent } from '../menu-button/menu-button.component';
import { MobileService } from '../../../services/mobile.service';
import { SwitcherLightDarkComponent } from '../switcher-light-dark/switcher-light-dark.component';	

@Component({
	selector: 'unir-ai-main-menu',
	standalone: true,
	imports: [
		CommonModule,
		ButtonModule,
		TranslateModule,
		ButtonModule,
		ChatCardComponent,
		ToastModule,
		CheckboxModule,
		FormsModule,
		MenuButtonComponent,
		SwitcherLightDarkComponent
	],
	providers: [MessageService],
	templateUrl: './main-menu.component.html',
	styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, AfterViewChecked {
	@ViewChild('scrollContainer') scrollableElement?: ElementRef;
	@HostListener('window:resize', ['$event'])
	onResize() {
		const element = this.scrollableElement?.nativeElement;
		if (element) {
			this.scroll =
				element.scrollHeight > element.clientHeight && element.scrollTop + element.clientHeight < element.scrollHeight;
		}
	}
	topicsSelected: number[] = [];
	topicsName: string[] = [];
	topicsBySubject!: TopicsBySubjectResponse;
	topicInfo!: TopicsResponse;
	menuActionType = MenuActionType;
	itemSelected = false;
	topicsInfo?: TopicsResponse[];
	topicIds: number[] = [];
	chatList!: ChatList;
	showTopic = false;
	currentChatId?: number;
	scroll = false;
	mobile!: boolean;

	constructor(
		protected route: ActivatedRoute,
		protected mobileService: MobileService,
		protected menuService: MenuService,
		private topicService: TopicService,
		private router: Router,
		private loadingService: LoadingService,
		private chatService: ChatService,
		private destroyRef: DestroyRef,
		private messageService: MessageService,
		private paramsService: ParamsService
	) {}

	ngOnInit() {
		this.getParams();
		this.listenMobileView();
		this.listenMenuService();
	}

	ngAfterViewChecked(): void {
		this.onResize();
	}

	redirectToSingleTopic(topicId: number) {
		this.itemSelected = true;
		this.router.navigate([appRoutesType.CHAT], { queryParams: { topicId: topicId } });
	}

	goToChatPage(allSubject = false) {
		this.itemSelected = true;

		this.router.navigate([appRoutesType.CHAT], {
			queryParams: {
				topicId: allSubject
					? this.topicsBySubject.topics.map((topic) => topic.id).join(',')
					: this.topicsSelected.join(',')
			}
		});
	}

	getOneTopicInfo(topicID: number) {
		this.loadingService.display(true);
		this.topicService
			.getTopicsById(topicID)
			.pipe(catchError(() => of(null)))
			.subscribe((topic) => {
				if (topic) {
					this.topicInfo = topic;
					this.paramsService.displaySubjectId(topic.subjectId);
				} else {
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Error al obtener la información del topic'
					});
				}
				this.loadingService.display(false);
			});
	}

	goBack() {
		this.router.navigate([appRoutesType.HOME], {
			queryParams: { subjectId: this.topicInfo.subjectId }
		});
		this.menuService.editAction({ action: this.menuActionType.BACK });
		this.topicsSelected = [];
		this.itemSelected = false;
	}

	openChat(chatId: number) {
		if (this.mobile) {
			this.menuService.closeMainMenu();
		}
		this.router.navigate([appRoutesType.CHAT, chatId], { queryParamsHandling: 'merge' });
		this.menuService.editAction({ action: this.menuActionType.OPEN_CHAT, value: chatId.toString() });
	}

	openNewChat() {
		this.menuService.closeMainMenu();
		this.menuService.editAction({ action: this.menuActionType.RESET_CHAT });
	}

	renameChat(chatInfo: { id: number; name: string }) {
		this.chatService
			.renameChat(chatInfo.id, chatInfo.name)
			.pipe(
				catchError(() => {
					this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al renombrar el chat' });
					return of(null);
				})
			)
			.subscribe();
	}

	removeChat(chatId: number) {
		this.chatList.chats = this.chatList.chats.filter((chat) => chat.chatId !== chatId);
		if (chatId === this.currentChatId) {
			this.menuService.editAction({ action: this.menuActionType.RESET_CHAT });
		}
		this.chatService.removeChat(chatId).subscribe();
	}

	private getSubjectTopics(subjectId: number) {
		this.loadingService.display(true);
		this.topicService
			.getTopicsBySubject(subjectId)
			.pipe(
				catchError(() => {
					this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener los temas' });
					return of(undefined);
				})
			)
			.subscribe((topics) => {
				if (topics) {
					this.loadingService.display(false);
					this.topicsBySubject = topics;
				}
			});
	}

	private getTopicsInfo(topicsId: number[]) {
		this.loadingService.display(true);
		this.topicService.getTopicsInfo(topicsId).subscribe((topic) => {
			this.loadingService.display(false);
			this.topicsInfo = topic.topics;
			this.itemSelected = true;
		});
	}

	private getChats() {
		this.chatService.getChats(this.topicIds).subscribe((chats) => {
			this.chatList = chats;
		});
	}

	private getParams() {
		this.paramsService.$chatId.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((chatId) => {
			this.currentChatId = chatId ?? undefined;
		});

		this.paramsService.$topicId.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((topicId) => {
			if (topicId) {
				this.topicIds = topicId;
				this.getTopicsInfo(topicId);
				this.getOneTopicInfo(topicId[0]);
				this.getChats();
			} else {
				this.itemSelected = false;
			}
		});

		this.paramsService.$subjectId.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((subjectId) => {
			if (subjectId) {
				this.getSubjectTopics(subjectId);
			}
		});
	}

	private listenMobileView() {
		this.mobileService.$mobileView.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((mobile) => {
			if (mobile !== null) {
				this.mobile = mobile;
			}
		});
	}

	private listenMenuService() {
		this.menuService.action$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((action) => {
			if (action.action === this.menuActionType.REFRESH_CHAT_LIST) {
				this.getChats();
			}
		});
	}
}
