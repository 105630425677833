import { HttpClientModule } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { provideMultitodoLibrary } from '@unir/multitodo-components-library';
import { env } from '../environments/environment';
import { appRoutes } from './app.routes';
import { MarkdownModule } from 'ngx-markdown';
import { provideNgIconsConfig } from '@ng-icons/core';

export const appConfig: ApplicationConfig = {
	providers: [
		provideMultitodoLibrary({
			url: env.url,
			oauthAllowedUrls: [
				env.url,
				'https://app-bff-preparador-dev-we-001.azurewebsites.net/api/v1',
				'https://app-preparador-documentmanager-be-dev-we-001.azurewebsites.net'
			]
		}),
		importProvidersFrom(
			HttpClientModule,
			BrowserModule,
			BrowserAnimationsModule,
			RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
			MarkdownModule.forRoot()
		),
		provideNgIconsConfig({
			size: "1.5rem",
			color: "#0a5cf5"
		})
	]
};
