import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ParamsService {
	private subjectId = new BehaviorSubject<number | null>(null);
	private topicId = new BehaviorSubject<number[] | null>(null);
	private chatId = new BehaviorSubject<number | null>(null);

	displaySubjectId(value: number | null) {
		this.subjectId.next(value);
	}

	displayTopicId(value: number[] | null) {
		this.topicId.next(value);
	}

	displayChatId(value: number | null) {
		this.chatId.next(value);
	}

	get $chatId() {
		return this.chatId.asObservable().pipe(distinctUntilChanged());
	}

	get $subjectId() {
		return this.subjectId.asObservable().pipe(distinctUntilChanged());
	}

	get $topicId() {
		return this.topicId.asObservable().pipe(
			distinctUntilChanged(),
			tap((res) => (res ? res.sort((a, b) => a - b) : null))
		);
	}
}
